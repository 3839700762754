* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}
.site-page .Intro{
    text-align: center;
}
.slider-drop h4 {
    font-size: 40px;
    color: #fff;
    width: 100%;
    text-align: center;
}

.slider-drop span {
    color: #fff;
}
.menu:hover{
    background-color: #fff !important;
    color: #d9a480 !important;
}
.home-slider{
    position: relative;
}
.home-slider::before{
    content: '';
    background-color: #000000a1;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
}
.site-page .xIntro {
    text-align: center;
}

.slick-prev,
.slick-next {
    display: none !important;
}

.site-page {
    padding: 50px;
}

.sqs-block {
    margin: 0 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #c7c7c7;
}

.details-form {
    width: 48%;
    margin: auto;
    padding: 50px 0 100px;
}

.footer {
    padding: 50px;
    background-color: #473c38;
}

.footer a {
    border-right: 2px solid #888888;
    padding: 0 5px;
    color: #fff;
}

.border-0 {
    border: 0 !important;
}

/* About */
.service-img .abt-Passion{
    color: #000;
}
.service-img .about-span {
    color: #000;
}
.abt-GreenEarth{
    font-size: 20px
}
.whatwedo-img{
    position: relative;
}
.whatwedo-img::before{
    content: '';
    background-color: #000000a1;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
}
.WhyGreenEarth{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.WhyGreenEarth::before{
    content: '';
    background-color: #000000a1;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
}

p {
    color: black;
    margin: 0 0 14.625px;
    text-align: justify;
}

.a-link {
    color: black;
    text-decoration: underline #f08d49 2px;
    font-size: 18px;
}

.box {
    background-color: #202329;
    padding: 81px 0;
    margin-bottom: 0;
}

.morty {
    color: black;
    text-decoration: underline #f08d49 2px;
}

.who {
    margin: 43.875px 0 14.625px;
    color: black;
    font-size: 23.7572px;
}

.service-li {
    font-size: 20px;
}
.service-img h1 {
    font-size: 44px;
    color: #fff;
    width: 45%;
    margin-top: 20px;
}

.service-img span {
    color: #fff;
    font-size: 20px;
}
/* certification */
.Certi-p{
    margin-top: 30px;
    font-size: 20px;
}
button{
    position: relative;
}
.btn{
    cursor: pointer;
}
.btn::before{
    content: '';
    width: 0%;
    transition: 0.5s;
    display: inline-block;
    background: #d9a480;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.btn:hover::before{
    width: 100%;
}
.btn:hover span{
    color: #473c38;
}
.btn span{
    z-index: 999;
    position: relative;
}


@media (max-width:1200px) {
    .slider-drop h4 {
        font-size: 31px;
    }
    .whaygreenearthp{
        font-size: 26px;
    }
}
@media (max-width:992px) {
    .whaygreenearthp {
        font-size: 24px;
    }

}
@media (max-width:768px) {
    .slider-drop h4 {
        font-size: 25px;
    }
    .whaygreenearthp {
        font-size: 19px;
    }

    .site-page{
        padding: 15px;
    }

    .details-form{
        width: 100%;
        padding: 15px;
    }

    .footer{
        padding: 15px;
    }
}
@media (max-width:576px) {
    .slider-drop h4 {
        font-size: 20px;
    }
}
